import React, { SetStateAction, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 16px;
    height: 24px;
    flex-basis: 24px;
`;

interface InputProps
    extends Omit<React.HTMLProps<HTMLInputElement>, "ref" | "as"> {
    changeHandler: (v: SetStateAction<boolean>) => void;
    initialValue?: boolean;
    id: string;
    name: string;
}

export const Checkbox = ({
    id,
    name,
    changeHandler,
    label,
    initialValue,
    ...inputProps
}: InputProps) => {
    const [value, setValue] = useState(Boolean(initialValue));

    const onChange = (v) => {
        setValue(v);
        changeHandler(v);
    };

    return (
        <Wrapper>
            <StyledInput
                id={id}
                name={name}
                type="checkbox"
                aria-label={label}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                {...inputProps}
            />
            <label htmlFor={name}>{label}</label>
        </Wrapper>
    );
};
