import { EasyAgileProductType } from "../../constants/products";

export const productTypeAddonKeyMap = {
    [EasyAgileProductType.Personas]: `com.easyagile.personas`,
    [EasyAgileProductType.Programs]: `com.easyagile.programs`,
    [EasyAgileProductType.Roadmaps]: `com.arijea.easy-agile-roadmaps`,
    [EasyAgileProductType.UserStoryMaps]: `com.kretar.jira.plugin.user-story-map`,
};

export const addonKeyProductTypeMap = {
    [`com.easyagile.personas`]: EasyAgileProductType.Personas,
    [`com.easyagile.programs`]: EasyAgileProductType.Programs,
    [`com.arijea.easy-agile-roadmaps`]: EasyAgileProductType.Roadmaps,
    [`com.kretar.jira.plugin.user-story-map`]:
        EasyAgileProductType.UserStoryMaps,
};
