import React, { SetStateAction, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import { isProductionDeployment, PRODUCTION_DOMAINS } from "../constants";
import { Form } from "../components/Form/Form";
import { Select } from "../components/Form/Select";
import { graphql } from "gatsby";
import {
    productTypeAddonKeyMap,
    addonKeyProductTypeMap,
} from "../components/ProductIntro/productTypeAddonKeyMap";
import { useState } from "react";
import { Checkbox } from "../components/Form/Checkbox";
import { Heading } from "../components/Typography/Heading";
import {
    PRODUCT_INTRO_PAGE_LOADED,
    PRODUCT_INTRO_SETUP,
} from "../types/productIntroTypes";

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
`;

const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 32px;
`;

const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: 0px;
`;

const TestProductIntro = ({ data: { allSanityProductIntro } }) => {
    const [selectedAddon, setSelectedAddon] = useState(null);
    const [isNewUser, setIsNewUser] = useState(false);
    const [isConnect, setIsConnect] = useState(false);
    const [isEvaluation, setIsEvaluation] = useState(false);
    const [isDataCenter, setIsDataCenter] = useState(false);
    const [paramsLoaded, setParamsLoaded] = useState(false);
    const location = useLocation();

    const iframe = useRef<HTMLIFrameElement>(null);

    const products = allSanityProductIntro.edges
        // Map to product names
        .map((product) => product.node.product)
        // Exclude duplicates
        .filter((product, index, self) => self.indexOf(product) === index)
        // Create an array of options with the addon IDs
        .map((product) => {
            return {
                value: productTypeAddonKeyMap[product],
                label: product,
            };
        });

    useEffect(() => {
        const params = queryString.parse(location.search);

        if (params.addon) {
            setSelectedAddon(productTypeAddonKeyMap[params.addon]);
        }
        if (params.isNewUser) {
            setIsNewUser(params.isNewUser === "true");
        }
        if (params.isConnect) {
            setIsConnect(params.isConnect === "true");
        }
        if (params.isEvaluation) {
            setIsEvaluation(params.isEvaluation === "true");
        }
        if (params.isDataCenter) {
            setIsDataCenter(params.isDataCenter === "true");
        }

        setParamsLoaded(true);
    }, [location.search]);

    if (isProductionDeployment()) return null;
    if (PRODUCTION_DOMAINS.includes(location.hostname)) return null;
    if (typeof window === "undefined") return null;

    // Product intro expects to be hosted by an app with Redux set up. When it first loads,
    // the page will post a message to the parent window informing it that it is ready, and
    // expects setup data (including product, SEN, isEval, etc).
    //
    // We fake this here by grabbing the message and replying with dummy data. There's more
    // to this interaction, which is for app functionality and analytics. We don't need to
    // worry about that right now, but we could hook in here.
    const handleMessage = (message) => {
        switch (message.data.type) {
            case PRODUCT_INTRO_PAGE_LOADED:
                iframe.current?.contentWindow?.postMessage(
                    setupData,
                    location.origin
                );
                break;
            default:
                break;
        }
    };

    /**
     * A wrapper function to update state and reload the iframe in one go.
     * @param value The new value to use
     * @param setter The setter returned from useState
     */
    const updateProperty = (
        value:
            | SetStateAction<string>
            | SetStateAction<string[]>
            | SetStateAction<boolean>,
        setter: (
            v:
                | SetStateAction<string>
                | SetStateAction<string[]>
                | SetStateAction<boolean>
        ) => void
    ) => {
        setter(value);
        iframe.current.contentWindow.location.reload();
    };

    window.addEventListener("message", handleMessage);

    const setupData = {
        addonKey: selectedAddon,
        isConnect,
        isEvaluation,
        isDataCenter,
        isNewUser,
        licenseType: "COMMERCIAL",
        licenseIdentifier: "SEN-1234-1234",
        type: PRODUCT_INTRO_SETUP,
    };

    if (!paramsLoaded) return null;

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <PageWrapper>
                <FormWrapper>
                    <Form>
                        <Heading level="h2">Configuration</Heading>
                        <Select
                            options={products}
                            isMulti={false}
                            id="product"
                            name="product"
                            label="Product"
                            initialValue={
                                selectedAddon
                                    ? {
                                          value: selectedAddon,
                                          label: addonKeyProductTypeMap[
                                              selectedAddon
                                          ],
                                      }
                                    : null
                            }
                            changeHandler={(e) =>
                                updateProperty(e, setSelectedAddon)
                            }
                        ></Select>
                        <Checkbox
                            id="isNewUser"
                            name="isNewUser"
                            label="Is New User"
                            initialValue={isNewUser}
                            changeHandler={(e) =>
                                updateProperty(e, setIsNewUser)
                            }
                        />
                        <Checkbox
                            id="isConnect"
                            name="isConnect"
                            label="Is Connect"
                            initialValue={isConnect}
                            changeHandler={(e) =>
                                updateProperty(e, setIsConnect)
                            }
                        />
                        <Checkbox
                            id="isEvaluation"
                            name="isEvaluation"
                            label="Is Evaluation"
                            initialValue={isEvaluation}
                            changeHandler={(e) =>
                                updateProperty(e, setIsEvaluation)
                            }
                        />
                        <Checkbox
                            id="isDataCenter"
                            name="isDataCenter"
                            label="Is Data Center"
                            initialValue={isDataCenter}
                            changeHandler={(e) =>
                                updateProperty(e, setIsDataCenter)
                            }
                        />
                    </Form>
                </FormWrapper>
                <Iframe ref={iframe} src={`${location.origin}/product-intro`} />
            </PageWrapper>
        </>
    );
};

export default TestProductIntro;

export const pageQuery = graphql`
    query ProductIntroPage {
        allSanityProductIntro {
            edges {
                node {
                    product
                }
            }
        }
    }
`;
