import React, { SetStateAction, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import UnstyledSelect from "react-select";

const customStyles = {
    control: (base) => ({
        ...base,
        minHeight: "64px",
        border: "1px solid #ffffff",
    }),
};

const StyledReactSelect = styled(UnstyledSelect)`
    border-radius: ${(p) => p.theme.borderRadius.s};
    background: ${(p) => p.theme.color.background.default};
    border: none;
    color: black;
    width: 100%;
`;

interface OptionType {
    value: string;
    label: string;
}

interface Props {
    id: string;
    name: string;
    label: string;
    options: OptionType[];
    isMulti?: boolean;
    initialValue?: OptionType;
    changeHandler: (
        v: SetStateAction<string> | SetStateAction<string[]>
    ) => void;
}

export const Select = ({
    id,
    name,
    label,
    options,
    isMulti,
    initialValue,
    changeHandler,
}: Props) => {
    return (
        <StyledReactSelect
            id={id}
            name={name}
            placeholder={label}
            options={options}
            defaultValue={initialValue}
            onChange={(e: OptionType | OptionType[]) => {
                changeHandler(
                    Array.isArray(e) ? e.map((option) => option.value) : e.value
                );
            }}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            isSearchable={options.length > 10}
            styles={customStyles}
        />
    );
};
